<template>
  <div v-if="isBase64">
    <img :class="customClass" :src="document" alt="img"/>
  </div>
  <div v-else>
    <img :class="customClass" :src="temporaryLinkUri" alt="img"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "S3ImageGenerator",
  props: {
    document: {
      type: String,
      required: true
    },
    isBase64: {
      type: Boolean,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      temporaryLinkUri: ""
    }
  },
  mounted() {
    this.generateTemporaryLink()
      .catch(error => {
        
      })
      .finally(() => {
        
      })
      .then(s3Data => {
        if (s3Data && s3Data.hasOwnProperty('status') && s3Data.status === 200) {
          this.temporaryLinkUri = s3Data.data.data.url;
        } else {
          
        }
      })
  },
  methods: {
    ...mapActions('s3ServiceModule', [
      'fetchFile'
    ]),

    async generateTemporaryLink() {
      try {
        if ( !this.isBase64 )
          return await this.fetchFile(this.document);
      }
      catch (e) {
        
      }
    }
  }
}
</script>